import {LocalizedDictionary} from '../Translator';

export default {
  'Spare parts search': 'Ersatzteilsuche',
  'Search spare parts': 'Ersatzteilsuche',
  'Search products': 'Produktverwendungssuche',
  Functions: 'Funktionen',
  'Recent search list': 'Suchliste',
  'Cumulative spare parts list': 'Kumulierte Ersatzteilliste',
  Search: 'Suchen',
  'Spare part number': 'Ersatzteilnummer',
  'Spare part list': 'Ersatzteilliste',
  'Order code / product root / serial number': 'Eingabe Bestellcode / Produktwurzel / Seriennummer',
  'Production date': 'Herstellungsdatum',
  DD: 'TT',
  MM: 'MM',
  YYYY: 'JJJJ',
  'Which spare parts are available for my product? Filling in the order code (e.g. FTC260-AA2A1 or PMC51-BA21JB1CGBTDJB) or the product id (e.g. FTC260 or 83F50) shows the relevant spare parts.':
    'Nach Eingabe des Bestellcode erhalten Sie alle passenden Ersatzteile. Durch die Eingabe des gesamten Bestellcodes (z.B. FTC260-AA2A1 oder PMC51-BA21JB1CGBTDJB) erhalten Sie eine gerätespezifische Ersatzteilliste. Durch die Eingabe der Produktwurzel (z.B. FTC260 oder 83F50) erhalten Sie die Liste aller Ersatzteile zum Gerät.',
  'For which product(s) can my spare part be used? Filling in the spare part order code (e.g. 71002865 or XPD0024) shows the relevant devices this spare part can be used in.':
    'In welchen Produkten kann ich ein Ersatzteil verwenden? Durch Eingabe der Ersatzteilnummer (z.B. 71002865 oder XPD0024) werden Ihnen Geräte angezeigt, in denen das Ersatzteil verwendet werden kann.',
  'Products for': 'Passende Produkte für',
  'Order code': 'Bestellcode',
  'Spare parts for': 'Ersatzteile für',
  'Product status': 'Produktstatus',
  Available: 'Verfügbar',
  'Phase out': 'Produktauslauf',
  Discontinued: 'Nicht mehr erhältlich',
  'Regarding quality- and security-aspects Endress+Hauser strongly recommends the exclusive use of genuine Endress+Hauser spare parts. The instrument may only be maintained and repaired by qualified personnel. The device documentation, applicable standards and legal requirements as well as any certificates have to be observed!':
    'Aus Qualitäts- und Sicherheitsüberlegungen empfiehlt Endress+Hauser, ausschliesslich originale Endress+Hauser Ersatzteile zu verwenden. \nDas Gerät darf nur von Fachpersonal repariert und gewartet werden. \nDabei sind die Gerätedokumentation, die einschlägigen Normen, die gesetzlichen Vorschriften und die Zertifikate zu beachten!',
  Description: 'Beschreibung',
  Recommended: 'Empfohlenes Ersatzteil (EE)',
  'Category code': 'Teileklasse (TK)',
  'Drawing Position Number': 'Zeichnungspositionsnummer (PN)',
  Group: 'Gruppe',
  Valid: 'Gültig',
  'Compatibility advices': 'Kompatibilitätshinweise (KH)',
  'Mounting advices': 'Montagehinweise (MH)',
  'Phase out date': 'Produktauslaufdatum',
  'Spare sensor until': 'Ersatzsensor bis',
  'Spare parts until': 'Verfügbarkeit von Ersatzteilen',
  'New alternative': 'Neue Alternative',
  'Maintenance advice': 'Wartungshinweis',
  'Order stop': 'Bestellstopp',
  'Calibration until': 'Kalibrierung bis',
  'Repair until': 'Reparatur bis',
  Open: 'Offen',
  Characteristic: 'Merkmal',
  Value: 'Wert',
  Type: 'Art',
  'Search date': 'Suchdatum',
  Filter: 'Filter',
  'Set filter': 'Filter setzen',
  'Check all': 'Alle markieren',
  'Recommended parts only': 'Nur empfohlende Ersatzteile anzeigen',
  Rec: 'EE',
  Tk: 'TK',
  Pos: 'PN',
  'Co.': 'KH',
  'Mo.': 'MH',
  Cancel: 'Abbruch',
  Confirm: 'Bestätigen',
  Clear: 'Löschen',
  'Add selection to cart': 'Auswahl zum Warenkorb hinzufügen',
  Warning: 'Warnung',
  'There are no items selected. Please use the checkboxes to make your selection':
    'Es wurden keine Artikel ausgewählt! Bitte markieren Sie die gewünschten Artikel in der Liste',
  Error: 'Fehler',
  'Please enter an order code': '',
  Ok: 'Ok',
  'not applicable': 'offen',
  'No data available for the entered search parameters': 'Zu diesen Suchparameter sind keine Daten vorhanden',
  Export: 'Export',
  'Download Excel': 'Excel herunterladen',
  'No results found': 'keine Ergebnisse gefunden',
  'Could not load cumulated data': 'Kumulierte Daten konnten nicht geladen werden',
  Tag: 'Tag',
  Seg: 'Seg',
  'Service segmentation': 'Servicesegmentierung',
  'Ordercode too short': 'Der eigegebene Bestellcode ist zu kurz',
  'Ordercode too long': 'Der eigegebene Bestellcode ist zu lang',
  'No data': 'Keine Daten für die eingegebenen Suchparameter verfügbar',
  accessories: 'Zubehör',
  'commissioning part': 'Inbetriebnahmeteil',
  'wear part': 'Verschleissteil',
  'spare part': 'Ersatzteil',
  tool: 'Tool',
  drawing: 'Zeichnung',
  'no value': 'ohne Wert',
  'service segmentation 1': 'Teile, die Grundkenntnisse erfordern.',
  'service segmentation 2': 'Teile, die Grundkenntnisse erfordern. Erfolgreiche Teilnahme an E+H-Grundlagenschulung ist Voraussetzung.',
  'service segmentation 3': 'Teile, die spezielle Kenntnisse erfordern. Erfolgreiche Teilnahme an E+H-Fortgeschrittenenschulung  ist Voraussetzung.',
  'service segmentation 4': 'Teile für Instandsetzung im Werk. Expertenwissen ist zwingend erforderlich.',
  none: 'Keine',
  'The entered order code is invalid': 'Der eingegebene Bestellcode ist ungültig',
  'Production numbers': 'Produktionsnummern',
} as LocalizedDictionary;
